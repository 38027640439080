import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTwitter, faLinkedin, faDiscord } from '@fortawesome/free-brands-svg-icons';
import profileImage from '../assets/profile.jpg';

const Home: React.FC = () => {
  const starsArray = Array.from({ length: 500 }, () => ({
    top: Math.random(),
    left: Math.random(),
  }));

  return (
    <section id="home" className="home">
      <div className="stars">
        {starsArray.map((star, i) => (
          <div
            key={i}
            className="star"
            style={{
              '--top': star.top,
              '--left': star.left,
            } as React.CSSProperties}
          ></div>
        ))}
      </div>
      <img src={profileImage} alt="Profile" className="profile-image" />
      <h1>Hi, I am Francoise</h1>
      <p>Soy un desarrollador especializado en FiveM. Me dedico a crear servidores y scripts personalizados.</p>
      <div className="social-icons">
        <a href="https://github.com/fkijs" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        <a href="https://x.com/Francoiseee2783" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a href="https://www.linkedin.com/in/tu-perfil-linkedin" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://discord.com/users/ak_franki" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faDiscord} size="2x" />
        </a>
      </div>
    </section>
  );
};

export default Home;