import React from 'react';
import { Link } from 'react-scroll';
import profileImage from '../assets/profile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCode, faUser, faBriefcase, faCogs, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  return (
    <header className="sidebar">
      <div className="profile">
        <img src={profileImage} alt="Profile" />
        <h2>Franco Méndez</h2>
      </div>
      <nav>
        <ul>
          <li>
            <Link to="home" smooth={true} duration={500}>
              <FontAwesomeIcon icon={faHome} /> Home
            </Link>
          </li>
          <li>
            <Link to="skills" smooth={true} duration={500}>
              <FontAwesomeIcon icon={faCode} /> Skills
            </Link>
          </li>
          <li>
            <Link to="about" smooth={true} duration={500}>
              <FontAwesomeIcon icon={faUser} /> Quién Soy
            </Link>
          </li>
          <li>
            <Link to="portfolio" smooth={true} duration={500}>
              <FontAwesomeIcon icon={faBriefcase} /> Portafolio
            </Link>
          </li>
          <li>
            <Link to="services" smooth={true} duration={500}>
              <FontAwesomeIcon icon={faCogs} /> Servicios
            </Link>
          </li>
          <li>
            <Link to="contact" smooth={true} duration={500}>
              <FontAwesomeIcon icon={faEnvelope} /> Contacto
            </Link>
          </li>
        </ul>
      </nav>
      <a href="https://discord.com/users/ak_franki" target="_blank" rel="noopener noreferrer" className="hire-me">
        Contrátame
      </a>
    </header>
  );
};

export default Header;