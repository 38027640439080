import React from 'react';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faCode, faTools } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const services = [
    { icon: faServer, title: 'Servidores completos de FiveM', description: 'Creación y configuración de servidores listos para usar.' },
    { icon: faCode, title: 'Desarrollo de scripts a medida', description: 'Scripts personalizados según las necesidades de tu servidor.' },
    { icon: faTools, title: 'Soporte y Mantenimiento', description: 'Mantenimiento regular y soporte técnico para tu servidor.' },
  ];

  return (
    <section ref={ref} id="services" className={`services section-animate ${inView ? 'visible' : ''}`}>
      <h2>SERVICIOS</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <div key={index} className="service">
            <FontAwesomeIcon icon={service.icon} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;