import React, { useState } from 'react';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Lógica para enviar el formulario
        console.log({ name, email, message });
    };

    return (
        <section className="contact">
            <h2>Contacto</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Nombre</label>
                    <input 
                        type="text" 
                        id="name" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        required 
                        placeholder="Tu Nombre"
                    />
                </div>
                <div>
                    <label htmlFor="email">Correo Electrónico</label>
                    <input 
                        type="email" 
                        id="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                        placeholder="Tu Correo Electrónico"
                    />
                </div>
                <div>
                    <label htmlFor="message">Mensaje</label>
                    <textarea 
                        id="message" 
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)} 
                        required 
                        placeholder="Tu Mensaje"
                    ></textarea>
                </div>
                <button type="submit">Enviar</button>
            </form>
        </section>
    );
};

export default ContactForm;