import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faDiscord } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer>
            <p>&copy; 2024 Francoise. Todos los derechos reservados.</p>
            <div>
                <a href="https://github.com/fkijs" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGithub} /> GitHub
                </a>
                <a href="https://linkedin.com/in/tu_usuario" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                </a>
                <a href="https://discord.com/users/ak_franki" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faDiscord} /> Discord
                </a>
            </div>
        </footer>
    );
};

export default Footer;