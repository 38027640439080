import React from 'react';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faDollarSign, faGlobe } from '@fortawesome/free-solid-svg-icons';

const Portfolio = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const projects = [
    {
      title: 'Servidor Roleplay de FiveM',
      description: 'Un servidor de FiveM totalmente personalizado...',
      icon: faServer,
      link: '#'
    },
    {
      title: 'Script de Facturas y Economía',
      description: 'Desarrollo de un sistema de economía robusto...',
      icon: faDollarSign,
      link: '#'
    },
    {
      title: 'Página Web Personal',
      description: 'Mi portafolio personal, donde muestro mis habilidades...',
      icon: faGlobe,
      link: '#'
    }
  ];

  return (
    <section ref={ref} id="portfolio" className={`portfolio section-animate ${inView ? 'visible' : ''}`}>
      <h2>Portafolio</h2>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <FontAwesomeIcon icon={project.icon} size="3x" />
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <a href={project.link} className="project-link">Ver Proyecto</a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;