import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Skills from './components/Skills';
import About from './components/About';
import Portfolio from './components/Portafolio';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Server from './components/Server'; // Asegúrate de que la ruta sea correcta

function App() {
  useEffect(() => {
    console.log("App mounted, you can add additional logic here.");
  }, []);

  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Header />
        <main>
          <Home />
          <div className="section-separator"></div>
          <Skills />
          <div className="section-separator"></div>
          <About />
          <div className="section-separator"></div>
          <Portfolio />
          <div className="section-separator"></div>
          <Services />
          <div className="section-separator"></div>
          <Server />
          <div className="section-separator"></div>
          <Contact />
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;