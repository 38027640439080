import React from 'react';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faServer, faDesktop } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <section ref={ref} id="about" className={`about section-animate ${inView ? 'visible' : ''}`}>
      <h2>Quién Soy</h2>
      <p>
        Soy un desarrollador con experiencia en la creación de soluciones personalizadas para servidores de FiveM...
      </p>
      <div className="divider"></div>
      <p>
        A lo largo de mi carrera, he trabajado en una variedad de proyectos...
      </p>
      <div className="divider"></div>
      <p>
        Mis habilidades incluyen:
      </p>
      <ul className="skills-list">
        <li>
          <FontAwesomeIcon icon={faCode} className="skill-icon" /> 
          <strong>Desarrollo Backend:</strong> Python, Node.js, Lua
        </li>
        <li>
          <FontAwesomeIcon icon={faDesktop} className="skill-icon" /> 
          <strong>Desarrollo Frontend:</strong> HTML/CSS, JavaScript, React
        </li>
        <li>
          <FontAwesomeIcon icon={faServer} className="skill-icon" /> 
          <strong>Configuración y Mantenimiento de Servidores de FiveM</strong>
        </li>
      </ul>
      <p>
        Estoy siempre dispuesto a enfrentar nuevos desafíos...
      </p>
    </section>
  );
};

export default About;