import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPython, faNodeJs, faReact, faHtml5, faJsSquare } from '@fortawesome/free-brands-svg-icons';
import { useInView } from 'react-intersection-observer';

const Skills = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  const skills = [
    { name: 'Python', level: 70, icon: faPython },
    { name: 'Node.js', level: 80, icon: faNodeJs },
    { name: 'Lua', level: 90, icon: faJsSquare },
    { name: 'HTML/CSS', level: 80, icon: faHtml5 },
    { name: 'JavaScript', level: 85, icon: faJsSquare },
    { name: 'React', level: 75, icon: faReact }
  ];

  return (
    <section id="skills" className={`skills section-animate ${inView ? 'visible' : ''}`} ref={ref}>
      <h2>My Skills</h2>
      <div className="skills-container">
        {skills.map((skill, index) => (
          <div
            key={index}
            className="skill"
            style={{ '--progress-width': `${skill.level}%` } as React.CSSProperties}
          >
            <div className="skill-info">
              <FontAwesomeIcon icon={skill.icon} size="2x" />
              <span>{skill.name}</span>
              <span>{skill.level}%</span>
            </div>
            <div className="progress-bar">
              <div className="progress"></div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;