import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons'; // Cambia el paquete para faDiscord

const Server = () => {
  return (
    <section className="server">
      <h2>NewDay Roleplay</h2>
      <div className="server-content">
        <FontAwesomeIcon icon={faGamepad} size="4x" className="server-icon" />
        <p>Únete a nuestro servidor de Roleplay para vivir experiencias únicas y personalizadas, pronto disponible en una nueva versión.</p>
        <a href="https://discord.gg/newdayroleplay" target="_blank" rel="noopener noreferrer" className="discord-link">
          <FontAwesomeIcon icon={faDiscord} size="2x" />
          &nbsp;Únete a nuestro Discord
        </a>
      </div>
    </section>
  );
};

export default Server;